import { graphql } from 'gatsby'
import get from 'lodash/get'
import Img from 'gatsby-image'
import React from 'react'
import Helmet from 'react-helmet'

import { siteMetadata } from '../../gatsby-config'
import Layout from 'components/layout'
import Meta from 'components/meta'
import Icon from 'components/icon'

import OpenSignImage from '../../content/images/open-sign.png'

class Profile extends React.Component {
  render() {
    const { location, data } = this.props
    const opensign = get(data, 'opensign.childImageSharp.sizes')
    const blackstar = get(data, 'blackstar.childImageSharp.sizes')

    return (
      <Layout location={location}>
        <Meta site={siteMetadata} title="Home" />
        <div>
          <section id="hero" className="fuzzy-bg bg-secondary text-center py-3">
            <div className="container ">
              <div className="row star-bg pt-5">
                <div className="py-5 my-5 col-md-10 offset-md-1 ">
                  <strong className="section-heading">Welcome to</strong>
                  <h1>#LIKE A <u>LOCAL</u></h1>
                  <p>We exist to help independent businesses find big brands willing to support them, and to help those big brands extend their voice to help their local communities immediately and directly.</p>
                  <p><a href="https://www.instagram.com/likealocal.to/" target="_blank"><Icon name="instagram"></Icon><strong>LikeALocal.TO</strong></a></p>
                </div>
              </div>              
            </div>
          </section>

          <section id="repeat-bg" className="bg-secondary text-center py-5">
            <div className="container my-5 py-4">
              <div className="row">
                <div className="col-10 offset-1 offset-md-0 col-md-12 bg-third py-5">
                  <div className="row">
                    <div className="col-md-10 offset-md-1 px-4 px-md-0">
                      <h2 className="section-heading">We’re all LOCALS.</h2>
                      <p>Local businesses are critically affected by the impact of COVID-19. By encouraging Toronto to eat #LikeALocal, shop #LikeALocal, and support their community #LikeALocal, we can all help. </p>
                      <h4>Because that’s what <br className="d-block d-sm-none"/><u className="star-bg">LOCALS</u>  do.</h4>
                    </div>
                  </div>                  
                </div>
              </div>
            </div>            
          </section>

          <section id="how-it-works" className="bg-fourth text-center">
            <hr/>
            <hr/>
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-left">
                  <div className="row flex-column-reverse flex-md-row">
                    <div className="col-md-8">
                      <h2 className="section-heading mt-4">HOW IT WORKS</h2>
                      <h3>For Big Brands</h3>
                      <ol>
                        <li>Simply search <a href="https://www.instagram.com/explore/tags/likealocal/" target="_blank">#LikeALocal</a> on Instagram (or any social listening platform)</li>
                        <li>Find a small business that needs a little boost</li>
                        <li>Repost their post and explain why you have done it, using #LikeALocal</li>
                        <li>Feel like you did your brand and the local economy good!</li>
                        <li>Consider donating more media than just social. For this we suggest contacting local businesses directly.</li>
                      </ol>

                      <h3 className="mt-4">For small, local businesses</h3>
                      <ol className="mb-5">
                        <li>Create your Instagram post as you normally would </li>
                        <li>Use the #LikeaLocal hashtag to indicate that you would love a little exposure from a Big Brand</li>
                        <li>You’re done! </li>
                      </ol>
                    </div>
                    <div className="col-6 offset-md-0 col-md-4">
                      <Img className="mt-md-4" sizes={blackstar} />
                    </div>
                  </div>                  
                </div>
              </div>
            </div>
            <hr/>
            <hr/>
          </section>

          <section
            className="bg-secondary text-center color-inverse"
            id="open"
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-10 offset-lg-1">
                  <img src={OpenSignImage} alt=""/>
                  {/* <h3 className="mt-5 mb-5">See some of our active<br/>brands and small businesses.</h3>
                  <p><a href="#" className="btn btn-fifth">SEE WHO’S PARTICIPATING</a></p> */}

                  <link href="//cdn-images.mailchimp.com/embedcode/classic-10_7.css" rel="stylesheet" type="text/css" />
                  <style type="text/css">
                  </style>
                  <div id="mc_embed_signup">
                  <form action="https://forsman.us16.list-manage.com/subscribe/post?u=b32bd4f1b253773cf94df1a24&amp;id=4295ac52b5" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
                    <h4 className="mt-3 text-center">Want to learn more about #LikeALocal?<br/>Leave your email and we’ll be in touch!</h4>
                      <div id="mc_embed_signup_scroll">
                    
                  <div className="indicates-required"><span className="asterisk">*</span> indicates required</div>
                  <div className="mc-field-group">
                    <label htmlFor="mce-EMAIL">Email Address  <span className="asterisk">*</span>
                  </label>
                    <input type="email" defaultValue="" name="EMAIL" className="required email" id="mce-EMAIL" />
                  </div>
                  <div className="mc-field-group">
                    <label htmlFor="mce-FNAME">First Name </label>
                    <input type="text" defaultValue="" name="FNAME" className="" id="mce-FNAME" />
                  </div>
                  <div className="mc-field-group">
                    <label htmlFor="mce-LNAME">Last Name </label>
                    <input type="text" defaultValue="" name="LNAME" className="" id="mce-LNAME" />
                  </div>
                    <div id="mce-responses" className="clear">
                      <div className="response" id="mce-error-response" style={{display:"none"}}></div>
                      <div className="response" id="mce-success-response" style={{display:"none"}}></div>
                    </div>
                      <div style={{position: "absolute", left: "-5000px"}} aria-hidden="true"><input type="text" name="b_b32bd4f1b253773cf94df1a24_4295ac52b5" tabIndex="-1" defaultValue="" /></div>
                      <div className="clear"><input type="submit" defaultValue="Submit" name="subscribe" id="mc-embedded-subscribe" className="btn btn-fifth mx-0" /></div>
                      </div>
                  </form>
                  </div>
                  
                  
                  <script type='text/javascript' src='//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js'></script>
                  <script type='text/javascript'>
                  {`(function($) {window.fnames = new Array(); window.ftypes = new Array();fnames[0]='EMAIL';ftypes[0]='email';fnames[1]='FNAME';ftypes[1]='text';fnames[2]='LNAME';ftypes[2]='text';fnames[3]='ADDRESS';ftypes[3]='address';fnames[4]='PHONE';ftypes[4]='phone';}(jQuery));var $mcj = jQuery.noConflict(true);`}
                  </script>
                  
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Profile

export const query = graphql`
  query ProfilePageQuery {
    blackstar: file(name: { eq: "black-star" }) {
      childImageSharp {
        sizes(quality: 100) {
          ...GatsbyImageSharpSizes_withWebp
        }
      }
    }
  }
`
